/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 .\model2.glb 
*/

import * as THREE from "three"
import React, { useEffect, useRef, useState } from "react"
import { useGLTF, useAnimations, PerspectiveCamera } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"

const color = new THREE.Color()

export default function Model({ scroll, onMeshSelect, disabled, ...props }) {
  const group = React.useRef()
  const { nodes, materials, animations } = useGLTF(`${process.env.PUBLIC_URL}/model.glb`)
  const initialPositions = useRef({})
  const initialRotations = useRef({})
  const originalColors = useRef({})
  const { actions } = useAnimations(animations, group)
  const [hovered, set] = useState()

  // Store initial positions, rotations, and colors
  useEffect(() => {
    console.log('Model mounted, storing initial positions and colors')
    group.current.children[0].children.forEach((child) => {
      if (child.name.includes('float')) {
        initialPositions.current[child.name] = child.position.clone()
        initialRotations.current[child.name] = child.rotation.clone()
        console.log(`Stored initial position for group ${child.name}:`, child.position)
      }
      
      // Store original colors for all meshes
      child.traverse(object => {
        if (object.type === 'Mesh' && object.material) {
          originalColors.current[object.name] = object.material.color.clone()
        }
      })
    })
  }, [])

  useEffect(() => {
    console.log('Animation setup:', actions["Animation"])
    if (actions["Animation"]) {
      actions["Animation"].play()
      actions["Animation"].paused = true
      actions["Animation"].time = 0
      actions["Animation"].setEffectiveTimeScale(1)
    }
  }, [actions])

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto"
  }, [hovered])

  // Reset hover state when disabled changes
  useEffect(() => {
    if (disabled) {
      set(null)
    }
  }, [disabled])

  const findSelectableGroup = (object) => {
    // Only meshes can be selectable, and only if they don't have noselect in their name
    return object.type === 'Mesh' && !object.name.includes('noselect') ? object : null;
  };

  useFrame((state) => {
    if (!actions["Animation"]) return
    
    actions["Animation"].time = THREE.MathUtils.lerp(actions["Animation"].time, actions["Animation"].getClip().duration * scroll.current, 0.05)
    
    // Apply floating animation to groups with float in name
    const sceneGroup = group.current.children[0];
    sceneGroup.children.forEach((child, index) => {
      // Handle float animation for groups
      if (child.type === 'Group' && child.name.includes('float')) {
        const et = state.clock.elapsedTime
        const initialPos = initialPositions.current[child.name]
        const initialRot = initialRotations.current[child.name]
        
        if (initialPos && initialRot) {
          child.position.y = initialPos.y + Math.sin((et + index * 2000) / 2) * 0.1
          child.rotation.x = initialRot.x + Math.sin((et + index * 2000) / 3) / 30
          child.rotation.y = initialRot.y + Math.cos((et + index * 2000) / 2) / 30
          child.rotation.z = initialRot.z + Math.sin((et + index * 2000) / 3) / 30
        }
      }

      // Apply colors to all meshes
      child.traverse(object => {
        if (object.type === 'Mesh' && object.material) {
          // Only apply hover color if this mesh is selectable and is currently hovered
          if (!object.name.includes('noselect') && hovered === object.name) {
            object.material.color.lerp(color.set("#f5316c"), 0.05)
          } else {
            // Reset to original color when not hovered
            const originalColor = originalColors.current[object.name]
            if (originalColor) {
              object.material.color.lerp(originalColor, 0.05)
            }
          }
        }
      })
    })
  })

  const handleMeshClick = (e) => {
    if (disabled) return;
    e.stopPropagation();
    const selectableGroup = findSelectableGroup(e.object);
    if (selectableGroup) {
      console.log('Group clicked:', selectableGroup.name);
      onMeshSelect(selectableGroup.name);
    }
  };

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene"
        onPointerOver={(e) => {
          if (disabled) return;
          e.stopPropagation();
          const selectableGroup = findSelectableGroup(e.object);
          if (selectableGroup) {
            console.log('Pointer over group:', selectableGroup.name);
            set(selectableGroup.name);
          }
        }}
        onPointerOut={(e) => {
          if (disabled) return;
          e.stopPropagation();
          set(null);
        }}
        onClick={handleMeshClick}>
        <group name="headphones_float" position={[0, 15.513, -0.18]}>
          <group name="headphones" rotation={[1.634, -0.163, 0.372]} scale={4.21}>
            <mesh name="headphones1" geometry={nodes.Cube001.geometry} material={materials.headphones} />
            <mesh name="headphones2_noselect" geometry={nodes.Cube001_1.geometry} material={materials['headphones.003']} />
            <mesh name="headphones3_noselect" geometry={nodes.Cube001_2.geometry} material={materials['headphones.002']} />
          </group>
        </group>
        <group name="bag_float" position={[0.18, 12.531, 0]}>
          <group name="bag" rotation={[0, -0.297, -0.192]} scale={2.24}>
            <mesh name="bag1_noselect" geometry={nodes.Plane002.geometry} material={materials.bag} />
            <mesh name="bag2" geometry={nodes.Plane002_1.geometry} material={materials['bag.001']} />
            <mesh name="bag3_noselect" geometry={nodes.Plane002_2.geometry} material={materials['bag.002']} />
          </group>
        </group>
        <group name="chair_float" position={[-0.16, 9.505, 0.11]}>
          <group name="chair" rotation={[-0.258, -0.045, 0.169]} scale={1.37}>
            <mesh name="chair1_noselect" geometry={nodes['Corte-ext2001'].geometry} material={materials.chair} />
            <mesh name="chair2" geometry={nodes['Corte-ext2001_1'].geometry} material={materials['chair.001']} />
          </group>
        </group>
        <group name="laptop_float" position={[0, 3.88, 0]} rotation={[2.728, -1.029, 2.71]} scale={3.7}>
          <mesh name="laptop_lid_noselect" geometry={nodes.laptop_lid_noselect.geometry} material={materials.laptop} position={[0.153, 0.007, -0.082]} rotation={[1.854, 0, 0]} />
          <mesh name="laptop_screen" geometry={nodes.laptop_screen.geometry} material={materials['laptop.001']} position={[0.153, 0.007, -0.082]} rotation={[1.854, 0, 0]} />
          <mesh name="laptop_base_noselect" geometry={nodes.laptop_base_noselect.geometry} material={materials.laptop} position={[0.139, 0.003, -0.065]} />
          <mesh name="laptop_keys" geometry={nodes.laptop_keys.geometry} material={materials['laptop.001']} position={[0.139, 0.003, -0.065]} />
        </group>
        <group name="seating_scene_float" position={[0, 6.745, 0]}>
          <mesh name="seating_scene_chair1" geometry={nodes.seating_scene_chair1.geometry} material={materials.seating_scene} position={[0, 0, -1]} scale={0.615} />
          <mesh name="seating_scene_chair2" geometry={nodes.seating_scene_chair2.geometry} material={materials.seating_scene} position={[1, 0, 0]} scale={0.615} />
          <mesh name="seating_scene_chair3" geometry={nodes.seating_scene_chair3.geometry} material={materials.seating_scene} position={[0, 0, 1]} scale={0.615} />
          <mesh name="seating_scene_chair4" geometry={nodes.seating_scene_chair4.geometry} material={materials.seating_scene} position={[-1, 0, 0]} scale={0.615} />
          <mesh name="seating_scene_floor_noselect" geometry={nodes.seating_scene_floor_noselect.geometry} material={materials['seating_scene.001']} position={[0, -0.8, 0]} scale={0.615} />
          <mesh name="seating_scene_table_noselect" geometry={nodes.seating_scene_table.geometry} material={materials['seating_scene.002']} scale={0.615} />
        </group>
        <group name="room">
          <mesh name="room1" geometry={nodes.room1.geometry} material={materials.room} position={[0.41, 0.26, -2.64]} />
          <mesh name="room1_noselect" geometry={nodes.room1_noselect.geometry} material={materials['room.001']} position={[0.41, 0.26, -2.64]} />
          <mesh name="room2" geometry={nodes.room2.geometry} material={materials.room2} position={[0.41, 0.26, -2.64]} />
          <mesh name="room2_noselect" geometry={nodes.room2_noselect.geometry} material={materials['room2.001']} position={[0.41, 0.26, -2.64]} />
        </group>
        <group name="architecture">
          <mesh name="Floor_noselect" geometry={nodes.Floor_noselect.geometry} material={materials.Material} position={[0, 0, -3]} scale={0.1} />
          <group name="house" position={[0.554, -3.453, -11.016]} scale={0.3}>
            <mesh name="house1_noselect" geometry={nodes.Plane081.geometry} material={materials.house} />
            <mesh name="house2" geometry={nodes.Plane081_1.geometry} material={materials['house.001']} />
          </group>
          <group name="tree001_noselect" position={[-3.614, -3, -12.785]} rotation={[0, -0.89, 0]} scale={0.25}>
            <mesh name="tree0011_noselect" geometry={nodes.Vert002.geometry} material={materials.trunk} />
            <mesh name="tree0012_noselect" geometry={nodes.Vert002_1.geometry} material={materials.leaves} />
          </group>
          <group name="tree002_noselect" position={[3.85, -3, -9.963]} rotation={[0, 1.396, 0]} scale={0.25}>
            <mesh name="tree0021_noselect" geometry={nodes.Vert003.geometry} material={materials.trunk} />
            <mesh name="tree0022_noselect" geometry={nodes.Vert003_1.geometry} material={materials.leaves} />
          </group>
          <group name="tree003_noselect" position={[-1.882, -10, -15.136]} rotation={[Math.PI, -0.82, Math.PI]} scale={0.25}>
            <mesh name="tree0031_noselect" geometry={nodes.Vert004.geometry} material={materials.trunk} />
            <mesh name="tree0032_noselect" geometry={nodes.Vert004_1.geometry} material={materials.leaves} />
          </group>
          <group name="tree_noselect" position={[3.85, -3, -7.62]} scale={0.25}>
            <mesh name="tree1_noselect" geometry={nodes.Vert011.geometry} material={materials.trunk} />
            <mesh name="tree2_noselect" geometry={nodes.Vert011_1.geometry} material={materials.leaves} />
          </group>
        </group>
        <PerspectiveCamera name="Camera" makeDefault far={1000} near={0.1} fov={47.5} position={[0, 15.623, 1.801]}>
          <directionalLight
            position={[10, 20, 15]}
            intensity={2}
          />
        </PerspectiveCamera>
      </group>
    </group>
  )
}

useGLTF.preload('/model.glb')
