import React from 'react';
import { motion } from 'framer-motion';

const LoadingScreen = ({ progress }) => {
  return (
    <motion.div
      className="loading-screen"
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="loading-content">
        <div className="loading-spinner"></div>
        <div className="loading-logo" style={{ 
          '--progress': `${100 - progress}%`
        }}>
          LOADING
        </div>
      </div>
    </motion.div>
  );
};

export default LoadingScreen; 