import React, { Suspense, useRef, useState, useEffect } from "react"
import { Canvas } from "@react-three/fiber"
import { Environment } from "@react-three/drei"
import Model from "./Model"
import Overlay from "./Overlay"
import Modal from "./components/Modal"
import ImageLoader, { preloadImages } from './components/ImageLoader'
import LoadingScreen from './components/LoadingScreen'
import { motion, AnimatePresence } from "framer-motion"

// Import images
import img_duffelbag from './assets/img_duffelbag.webp'
import img_productviewer from './assets/img_productviewer.jpg'
import img_architecture from './assets/img_architecture.webp'
import img_virtualspaces from './assets/img_virtualspaces.webp'
import img_retail from './assets/img_retail.webp'
import img_interiordesign from './assets/img_interiordesign.jpg'

const modalContent = {
  headphones1: {
    type: "video",
    videoUrl: "https://www.youtube.com/embed/Xvts0K-41E8?si=6fxuYg20KlPB2zvR"
  },
  bag2: {
    type: "image",
    content: "Engaging<br />Displays",
    image: img_duffelbag,
    alt: "living room scene in a modern home with a large window and a view of the mountains."
  },
  chair2: {
    type: "image",
    content: "Product<br />Viewers",
    image: img_productviewer,
    alt: "mobile device showing an augmented reality digital table in place in a living room.",
    url: "https://www.growm.io/ar-shopping-experience/"
  },
  laptop_keys: {
    type: "image",
    content: "Interactive<br />e-commerce",
    image: img_architecture,
    alt: "minimalist concept virtual space retail store with a selection of different online products on display.",
    url: "https://www.growm.io/ecommerce/"
  },
  laptop_screen: {
    type: "image",
    content: "Interactive<br />e-commerce" ,
    image: img_retail,
    alt: "minimalist concept virtual space retail store with a selection of different online products on display.",
    url: "https://www.growm.io/ecommerce/"
  },
  seating_scene_chair1: {
    type: "image",
    content: "Virtual<br />Spaces",
    image: img_virtualspaces,
    alt: "cgi render of a modern living room with mezzanine level and polished floor with brown and white furniture.",
    url: "https://www.growm.io/3d-virtual-space-solution/"
  },
  seating_scene_chair2: {
    type: "image",
    content: "Virtual<br />Spaces",
    image: img_virtualspaces,
    alt: "cgi render of a modern living room with mezzanine level and polished floor with brown and white furniture.",
    url: "https://www.growm.io/3d-virtual-space-solution/"
  },
  seating_scene_chair3: {
    type: "image",
    content: "Virtual<br />Spaces",
    image: img_virtualspaces,
    alt: "cgi render of a modern living room with mezzanine level and polished floor with brown and white furniture.",
    url: "https://www.growm.io/3d-virtual-space-solution/"
  },
  seating_scene_chair4: {
    type: "image",
    content: "Virtual<br />Spaces",
    image: img_virtualspaces,
    alt: "cgi render of a modern living room with mezzanine level and polished floor with brown and white furniture.",
    url: "https://www.growm.io/3d-virtual-space-solution/"
  },
  room1: {
    type: "image",
    content: "Explorable<br />Design",
    image: img_interiordesign,
    alt: "monotone cgi render of a modern minimalistic living room with wood slat panels",
    url: "https://www.growm.io/interior-design/"
  },
  room2: {
    type: "image",
    content: "Explorable<br />Design",
    image: img_interiordesign,
    alt: "monotone cgi render of a modern minimalistic living room with wood slat panels",
    url: "https://www.growm.io/interior-design/"
  },
  house2: {
    type: "image",
    content: "Concept<br />storytelling",
    image: img_architecture,
    alt: "artistic render of a luxury modern home in a woodland setting floor to ceiling windows",
    url: "https://www.growm.io/architecture/"
  }
}

const ModalContent = ({ content }) => {
  const [showCaption, setShowCaption] = useState(false);

  useEffect(() => {
    if (content?.type === 'image') {
      setShowCaption(false);
      const timer = setTimeout(() => {
        setShowCaption(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [content]);

  if (!content) return null;

  switch (content.type) {
    case "info":
      return (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">{content.title}</h2>
          <p className="text-gray-600 mb-4">{content.content}</p>
        </div>
      );

    case "video":
      return (
        <iframe
          className="modal_video"
          src={content.videoUrl}
          title={content.title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );

    case "image":
      return (
        <div className="text-center">
          <div className="modal_image_container">
              <img
                src={content.image}
                alt={content.alt}
                className="modal_image"
              />
              <div 
                className={`modal_image_overlay ${content.url ? 'has-link' : ''}`}
                onClick={content.url ? () => window.location.href = content.url : undefined}
              >
                <motion.p 
                  className="modal_image_caption"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                />
              </div>
          </div>
        </div>
      );

    default:
      return (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">{content.title}</h2>
          <p className="text-gray-600">{content.content}</p>
        </div>
      );
  }
};

export default function App() {
  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)
  const [selectedMesh, setSelectedMesh] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [loadingProgress, setLoadingProgress] = useState(0)

  useEffect(() => {
    console.log('Selected mesh changed:', selectedMesh)
    if (selectedMesh) {
      setIsDisabled(true)
    }
  }, [selectedMesh])

  const handleMeshSelect = (meshName) => {
    console.log('App received mesh selection:', meshName)
    setSelectedMesh(meshName)
  }

  const handleCloseModal = () => {
    console.log('Closing modal')
    setSelectedMesh(null)
    setTimeout(() => {
      setIsDisabled(false)
    }, 300)
  }

  return (
    <>
      <AnimatePresence>
        {isLoading && <LoadingScreen progress={loadingProgress} />}
      </AnimatePresence>
      <Suspense fallback={null}>
        <ImageLoader 
          onLoad={() => setIsLoading(false)} 
          onProgress={setLoadingProgress}
        />
        <Canvas shadows eventSource={document.getElementById("root")} eventPrefix="client">
          <fog attach="fog" args={['black', 0.1, 11]} />
          <ambientLight intensity={1} />
          <Model scroll={scroll} onMeshSelect={handleMeshSelect} disabled={isDisabled} />
          <Environment preset="city" />
        </Canvas>
        <Overlay ref={overlay} caption={caption} scroll={scroll} />
        <Modal isOpen={!!selectedMesh} onClose={handleCloseModal}>
          {selectedMesh && <ModalContent content={modalContent[selectedMesh]} />}
        </Modal>
      </Suspense>
    </>
  )
}
