import React, { useEffect, useState } from 'react';

const ImageLoader = ({ onLoad, onProgress }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const preloadImages = async () => {
      try {
        // Get all image paths from the assets folder
        const imagePaths = require.context('../assets', false, /\.(png|jpe?g|svg|webp)$/).keys();
        const totalImages = imagePaths.length;
        let loadedImages = 0;

        const loadPromises = imagePaths.map(path => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = require(`../assets${path.replace('./', '/')}`);
            img.onload = () => {
              loadedImages++;
              const newProgress = (loadedImages / totalImages) * 100;
              setProgress(newProgress);
              onProgress?.(newProgress);
              resolve();
            };
            img.onerror = reject;
          });
        });

        await Promise.all(loadPromises);
        onLoad();
      } catch (error) {
        console.error('Error loading images:', error);
        onLoad(); // Still call onLoad even if there's an error
      }
    };

    preloadImages();
  }, [onLoad, onProgress]);

  return null; // This component doesn't render anything
};

export default ImageLoader; 